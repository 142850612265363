import React, {
  useMemo,
  useState
} from 'react';
import { Line, Label, Text, Tag, Circle } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { useChange, useLabelMap } from '../../store';
import { actions } from '../../../../redux/slices/annotation';
import useFlagCoordinates from '../../../../util/useFlagCoordinates';

const regionColors = {
  gt: {
    [true]: {
      text: '#737373',
      shape: '#5eff60',
    },
    [false]: {
      text: '#737373',
      shape: '#99cc9b',
    },
  },
  pred: {
    [true]: {
      text: '#e6e6e6',
      shape: 'red',
    },
    [false]: {
      text: '#e6e6e6',
      shape: 'grey',
    },
  },
  default: {
    [true]: {
      text: '#e6e6e6',
      shape: '#3f51b595',
    },
    [false]: {
      text: '#e6e6e6',
      shape: '#2135a5',
    },
  },
  new: {
    [undefined]: {
      text: '#e6e6e6',
      shape: '#3f51b5',
    },
    [true]: {
      text: '#e6e6e6',
      shape: '#3f51b5',
    },
    [false]: {
      text: '#e6e6e6',
      shape: '#2135a5',
    },
  },
};

const flagConfigs = {
  0: {
    title: 'Bilinmeyen Flag',
    description: '??????????',
    color: '#858585',
  },
  1: {
    title: 'Hatalı "Polygonal Box"',
    description: 'Bu "polygonal box" bozuk olarak işaretlenmiş.',
    color: '#9617ea',
  },
  2: {
    title: 'Şüpheli Anomali',
    description: 'Bu "polygonal box" Burak Hoca\'ya teyit ettirilecek.',
    color: '#ea17e2',
  },
  3: {
    title: 'Onaylı',
    description: 'Bu "polygonal box" Burak Hoca tarafından onaylandı!',
    color: '#13e300',
  },
};

function useRegionsStore() {
  const region = useSelector((state) => state.annotation.region);
  const selectedRegionId = useSelector(
    (state) => state.annotation.selectedRegionId
  );
  const regionList = useSelector((state) => state.annotation.regionList);

  const dispatch = useDispatch();

  return {
    region,
    selectedRegionId,
    regionList,

    selectRegion: (selectRegionId) =>
      dispatch(actions.selectRegion(selectRegionId)),
  };
}

function getFlagConfigs(flagId) {
  if (flagId in flagConfigs) return flagConfigs[flagId];
  return flagConfigs[0];
}

function PolygonalBoxFlag({ flags, imageSize, coords }) {
  const [open, setOpen] = useState(false);
  const { width, height } = imageSize;

  const { maxX, minY } = coords;
  function renderDetails() {  
    // Expand functionality of flags
    return flags.map((flagId, i) => {
      const config = getFlagConfigs(flagId);
      return (
        <Label x={maxX * width} y={minY * height}>
          <Tag fill={config.color} />
          <Text text={config.title} fontSize={14} fill={`#ffff`} padding={2} />
        </Label>
      );
    });
  }

  // Render flags
  return (
    <>
      <Label
        onMouseOver={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        offsetY={18}
        offsetX={-5}
        x={maxX * width}
        y={minY * height}
      >
        <Tag fill={getFlagConfigs(flags[0]).color} />
        <Text
          text={`${flags.length}`}
          fontSize={14}
          fill={'#ffff'}
          padding={2}
        />
      </Label>
      {open && renderDetails()}
    </>
  );
}

export default function Regions({
  region,
  imageSize,
  clickable,
  hideBoxes,
  isPatched,
  flaggedOnly,
  showPred,
  showGt,
  threshold,
  type,
  boxColor = "new",
  dragFunction,
  lineThickness,
  ...props
}) {
  const [color, setColor] = useState(regionColors[boxColor][region.isValid]);
  // const { selectRegion } = useRegionsStore();
  const informChange = useChange(state => state.changed);
  const patchColor = useSelector((state) => state.annotation.options.patchColor);
  const labels = useLabelMap((state) => state.labels);
  let text = useMemo(() => labels[region?.labelId], [labels, region.labelId]);
  const showLabelsSet = useMemo(() => new Set(props.showLabels), [props.showLabels])
  let { points, flags, confidence, labelId } = region; // deleted isValid and labelId
  const [circleRadius, setCircleRadius] = useState(false);
  
  // if (region.isValid) {
  //   useEffect(() => setColor(regionColors['gt'][true]), [region.isValid]);
  // } else if (isValid !== undefined) {
  //   // TODO: Refactor here after added prediction support
  //   text += `: ${parseInt(confidence * 1000) / 10}%`;
  //   useEffect(() => setColor(regionColors['pred'][false]), [region.isValid]);
  // }

  const { width, height } = imageSize;
  const {maxX, minX, minY} = useFlagCoordinates(points);
   
  function handleMouseEnter() {
    if (clickable) {
      props.handleHover(true);
      // setColor(primary)
    }
  }

  function handleMouseEnterCircle(e) {
    e.target.radius(0.2 * lineThickness)
    setCircleRadius(true);
  }

  function handleMouseLeaveCircle(e) {
    e.target.radius(0.1 * lineThickness)
    setCircleRadius(false);
  }

  function handleMouseExit() {
    if (clickable) {
      props.handleHover(false);
      // setColor(secondary)
    }
  }

  function handleClick() {
    if (clickable) {
      if ('handleClick' in props) {
        informChange()
        props.handleClick(region);
      } else {
        informChange()
        region.isValid = !region.isValid;
        setColor(regionColors[boxColor][region.isValid]);
      }
    } 
  }

  const clickableProps = {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseExit,
  };

  if (!showLabelsSet.has(labelId) && type !== "temp") { // TODO: do best practice here
    return <></>
  }

  if(hideBoxes) {
    return <></>
  }

  if (!flags?.[0] && flaggedOnly) {
    return <></>;
  }

  if (!showGt && showGt !== undefined) {
    return <></>;
  }

  if ((!showPred && showPred !== undefined) || (confidence && !(confidence <= threshold?.[1] && confidence >= threshold?.[0]))) {
    return <></>;
  }

  return (
    <>
      {/* const isSelected = region.labelId === selectedRegionId; */}
      {/* first we need to erase previous drawings */}
      {/* we can do it with  destination-out blend mode */}
      {/* <Line
        globalCompositeOperation="destination-out"
        points={points.flatMap((p) => [p?.x * width, p?.y * height])}
        stroke={color.shape}
        listening={false}
        closed
      /> */}
      {/* then we just draw new region */}
      <Line
        {...clickableProps}
        name="region"
        points={points.flatMap((p) => [p?.x * width, p?.y * height])} 
        stroke={isPatched ? patchColor : color.shape}
        strokeWidth={0.15 * lineThickness}
        opacity={1}
        onClick={handleClick}
      />
      {type === "temp" ? points.map((p, index) => {
          return (
            <Circle
              name="region"
              x={p.x * width}
              y={p.y * height}
              radius={0.1 * lineThickness}
              onDragMove={(e) => dragFunction(index, e, width, height)}
              onMouseEnter={(e) => handleMouseEnterCircle(e)}
              onMouseLeave={(e) => handleMouseLeaveCircle(e)}
              key={index}
              draggable={true}
              fill={'#fff'}
              opacity={1}
            />
          )
      }) : null }
      
      {text && !isPatched && (
        <Label
          {...clickableProps}
          x={minX * width}
          y={minY * height}
          offsetY={50}
          offsetX={10}
          onClick={handleClick}
        >
          <Tag fill={color.shape} />
          <Text text={text + (confidence ? `: ${parseInt(confidence * 1000) / 10}%` : '')} fontSize={14} fill={color.text} padding={2} />
        </Label>
      )}
      {Boolean(flags?.length) && !isPatched && (
        <PolygonalBoxFlag
          // scale={scale}
          flags={flags}
          imageSize={imageSize}
          coords={{ maxX, minY }}
          // commonProps={commonProps}
        />
      )}
    </>
  );
};
